import React, { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import FranceConnect from "../../components/FranceConnect/FranceConnect"
import BlueRoundedOr from "../../components/BlueRoundedOr/BlueRoundedOr"
import Alert from "../../components/Alert/Alert"
import Input from "../../components/Input/Input"
import PasswordPolicy from "../../components/PasswordPolicy/PasswordPolicy"
import { CONSTANTS } from "../../utils/constants"
import AuthLink from "../../components/AuthLink/AuthLink"
import DileCaptcha from "../../components/DileCaptcha/DileCaptcha"
import SubmitButton from "../../components/SubmitButton/SubmitButton"
import Callout from "../../components/Callout/Callout"
import { BaseResponse, createAccount } from "../../services/api"
import { AxiosError } from "axios"
import { useNavigate } from "react-router-dom"
import CGUCheckbox from "../../components/CGUCheckbox/CGUCheckbox"
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert"
import { addErrorIf, redirectToApp } from "../../utils/utils"
import Description from "../../components/Description/Description"
import AccountExistsOrExpiredLinkError from "./AccountExistsOrExpiredLinkError"

const CreateAccountForm: React.FC = () => {
    const [title, setTitle] = useState<string>(CONSTANTS.DEFAULT_CREATE_ACCOUNT_PAGE_TITLE)
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>("")
    const [acceptCGU, setAcceptCGU] = useState<boolean>(false)
    const [captchaUuid, setCaptchaUuid] = useState<any>()
    const [captchaCode, setCaptchaCode] = useState<any>()
    const captchetatRef = useRef<any>(null)
    const [invalidCaptcha, setInvalidCaptcha] = useState<string>()
    const [errorMessages, setErrorMessages] = useState<string[]>([])
    const [accountAlreadyExists, setAccountAlreadyExists] = useState<string>()
    const [invalidPasswordFormat, setInvalidPasswordFormat] = useState<string>()
    const [notSamePassword, setNotSamePassword] = useState<string>()
    const navigate = useNavigate()

    function handleError(error: AxiosError<BaseResponse<string>>) {
        const INVALID_CAPTCHA = "600"
        const INVALID_PASSWORD_FORMAT = "200"
        const ACCOUNT_ALREADY_EXISTS = "300"
        const PASSWORD_AND_CONFIRMATION_NOT_SAME = "700"
        const ERRORS = error.response?.data?.errors || {}
        const FORM_ERRORS: string[] = []


        addErrorIf(INVALID_CAPTCHA in ERRORS, FORM_ERRORS, INVALID_CAPTCHA, setInvalidCaptcha)
        addErrorIf(ACCOUNT_ALREADY_EXISTS in ERRORS, FORM_ERRORS, ACCOUNT_ALREADY_EXISTS, setAccountAlreadyExists)
        addErrorIf(INVALID_PASSWORD_FORMAT in ERRORS, FORM_ERRORS, INVALID_PASSWORD_FORMAT, setInvalidPasswordFormat)
        addErrorIf(PASSWORD_AND_CONFIRMATION_NOT_SAME in ERRORS, FORM_ERRORS, PASSWORD_AND_CONFIRMATION_NOT_SAME, setNotSamePassword)
        setErrorMessages(FORM_ERRORS)

        setAcceptCGU(false)
        captchetatRef.current?.reloadImage()
        setCaptchaCode("")

        setTitle("Erreur - " + CONSTANTS.DEFAULT_CREATE_ACCOUNT_PAGE_TITLE)
        window.scrollTo({ top: 0 })
    }

    const handleSuccess = (response: BaseResponse<string>) => {
        if (response.data) {
            navigate("/create-account", { state: { resendEmailCode: response.data, email: email } })
        } else {
            redirectToApp()
        }
    }

    const submitCreateAccount = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setInvalidCaptcha("")
        setInvalidPasswordFormat("")
        setNotSamePassword("")
        setAccountAlreadyExists("")
        setErrorMessages([])

        const requestBody = {
            email: email,
            password: password,
            passwordConfirmation: passwordConfirmation,
            acceptCGU: acceptCGU,
            captcha: {
                code: captchaCode,
                uuid: captchaUuid
            }
        }

        createAccount(requestBody)
            .then(handleSuccess)
            .catch(handleError)
    }

    return (
        <>
            <Helmet><title>{title}</title></Helmet>
            {
                accountAlreadyExists ?
                    <AccountExistsOrExpiredLinkError title="Un compte existe déjà pour cette adresse email"
                        links={[{ title: "Se connecter", to: "/login" },
                        { title: "Mot de passe oublié ?", to: "/reset-password-request" }]} type="error" />
                    :
                    <>
                        <ErrorAlert errors={errorMessages} />
                        <Description title="Choisir mes identifiants" />
                        <FranceConnect />
                        <BlueRoundedOr />
                        <h2 className="fr-h5">Créer vos identifiants</h2>
                        <form onSubmit={submitCreateAccount}>
                            <Alert className="fr-mb-6w">
                                <p>Les <strong>informations </strong>demandées sont <strong>obligatoires </strong></p>
                            </Alert>
                            <Input id="email" type="email" name="email" required
                                label="Adresse électronique (exemple: nom@exemple.fr)"
                                hint="Par exemple : nom@example.com" value={email} onChange={setEmail}
                                error={accountAlreadyExists} />
                            <PasswordPolicy />
                            <Input id="password" type="password" name="password" required label="Votre mot de passe"
                                value={password} onChange={setPassword} error={invalidPasswordFormat} />
                            <Input id="password-confirmation" type="password" name="password-confirmation" required
                                label="Confirmez votre mot de passe" value={passwordConfirmation} onChange={setPasswordConfirmation}
                                error={notSamePassword} />
                            <AuthLink to="/login" message="Vous avez déjà vos identifiants ?" />
                            <Callout className="fr-mb-6w" icon="fr-icon-feedback-line" color="clear">
                                <p>Ce service en ligne <strong>n'est pas lié</strong> à votre compte service-public.fr</p>
                            </Callout>
                            <CGUCheckbox id="accept-cgu" name="accept-cgu" value={acceptCGU} onChange={setAcceptCGU} />
                            <DileCaptcha captchaCode={captchaCode} setCaptchaCode={setCaptchaCode} setCaptchaUuid={setCaptchaUuid}
                                captchetatRef={captchetatRef} error={invalidCaptcha} />
                            <SubmitButton title="Continuer" />
                            <p>Vous pouvez <a href="https://www.interieur.gouv.fr/contact/signaler-probleme-sur-site">nous écrire</a> si vous avez des difficultés à créer votre compte.
                            </p>
                        </form>
                    </>
            }

        </>
    )
}

export default CreateAccountForm